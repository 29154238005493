const setWorksTabs = () => {
    const $worksToggle = $('.js-work-toggle');
    const $workCurrent = $worksToggle.find('.js-work-current');
    const $tabList = $('.js-works-tab-list');
    const $tabs = $tabList.find('.js-work-button');
    const $tables = $('.js-work-table');
    let isExpanded;

    const toggleMenu = (evt) => {
        evt.preventDefault();
        $worksToggle.toggleClass('works__tab-toggle--opened');
        isExpanded = $worksToggle.attr('aria-expanded') === 'true';
        $worksToggle.attr('aria-expanded', !isExpanded);
        isExpanded = !isExpanded;
    };

    const handleTabClick = (evt) => {
        evt.preventDefault();
        const index = $tabs.index($(evt.target));

        if ( typeof index !== 'number' ||
            $(evt.target).hasClass('works__tab-button--active')
        ) return;

        $tabs.each((i, tab) => {
            $(tab).removeClass('works__tab-button--active');
        });
        $(evt.target).addClass('works__tab-button--active');
        $workCurrent.text($(evt.target).text());

        $tables.each((i, table) => {
            $(table).removeClass('works__table--active');
        });
        $tables.eq(index).addClass('works__table--active');

        if (isExpanded) toggleMenu(evt);
    };

    $worksToggle.on('click', toggleMenu);
    $tabList.on('click', handleTabClick);
};
