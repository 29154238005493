function initSlider() {
    const $sliderContainer = $('.js-slider-container')
    const $buttonPrev = $sliderContainer.find('.js-slider-prev');
    const $buttonNext = $sliderContainer.find('.js-slider-next');
    const $circle = $sliderContainer.find('.js-review-circle');
    $sliderContainer.find('.js-slider').slick(slidersConfig);


    $buttonPrev.on('mouseover', () => {
        $circle.removeClass('review__button-circle--next');
        requestAnimationFrame(() => {
            $circle.addClass('review__button-circle--prev');
        });
    });

    $buttonNext.on('mouseover', () => {
        $circle.removeClass('review__button-circle--prev');
        requestAnimationFrame(() => {
            $circle.addClass('review__button-circle--next');
        });
    });

    $buttonPrev.on('mouseout', () => {
        $circle.removeClass('review__button-circle--next');
        $circle.removeClass('review__button-circle--prev');
    });

    $buttonNext.on('mouseout', () => {
        $circle.removeClass('review__button-circle--next');
        $circle.removeClass('review__button-circle--prev');
    });
}

const slidersConfig = {
    dots: true,
    arrows: true,
    prevArrow: $('.js-slider-prev'),
    nextArrow: $('.js-slider-next'),
    infinite: false,
    adaptiveHeight: true,
    mobileFirst: true,
    responsive: [{
        breakpoint: 1199,
        settings: {
            adaptiveHeight: false,
        },
    }],
};
