const setHeader = () => {
    const $header = $('.js-header');
    const $logo = $header.find('.js-logo');
    const $headerToggle = $header.find('.js-header-toggle');
    const $headerNav = $header.find('.js-header-nav');
    let isExpanded;

    const toggleMenu = (evt) => {
        evt.preventDefault();
        $header.toggleClass('header--expanded');
        isExpanded = $headerToggle.attr('aria-expanded') === 'true';
        $headerToggle.attr('aria-expanded', !isExpanded);
        isExpanded = !isExpanded;
    };

    const handleNavClick = (evt) => {
        evt.preventDefault();
        const dataNav = $(evt.target).data('nav');
        if (!dataNav) return;

        if (isExpanded) {
            toggleMenu(evt);
        }

        const yCoord = $(`[data-section=${dataNav}]`).position().top - $header.height();

        $(window).scrollTop(yCoord);

        evt.target.blur();
    }

    const setHeaderAnimation = () => {
        if (!'IntersectionObserver' in window) return;

        const $header = $('.js-header');
        const $content =$('.js-header-observer');

        const observerOptions = {
            rootMargin: '-100px',
            threshold: 0,
        };


        const observerCallback = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    $header.removeClass('header--animate');
                } else {
                    $header.addClass('header--animate');
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        observer.observe($content[0]);
    };

    setHeaderAnimation();
    $headerToggle.on('click', toggleMenu);
    $headerNav.on('click', handleNavClick);
    $logo.on('click', () => {
        $(window).scrollTop(0);
    });
};
