const setPriceBaloon = () => {
    const $priceLink = $('.js-price-link');
    const $priceVarClose = $priceLink.find('.js-price-var-close');

    const openBaloon = (evt) => {
        evt.preventDefault();
        $(evt.target).addClass('price__link--opened');
        $(evt.target).off('touchstart', openBaloon);
        $(evt.target).on('touchstart', closeBaloon);
    }

    const closeBaloon = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        if ($(evt.target).hasClass('js-price-link')) {
            $(evt.target).removeClass('price__link--opened');
            $(evt.target).off('touchstart', closeBaloon);
            $(evt.target).on('touchstart', openBaloon);
            return;
        }

        const $link = $(evt.target).closest('.js-price-link');
        $link.removeClass('price__link--opened');
        $link.off('touchstart', closeBaloon);
        $link.on('touchstart', openBaloon);
    }

    $priceLink.each((index, link) => {
        $(link).on('touchstart', openBaloon);
    })

    $priceVarClose.each((index, button) => {
        $(button).on('touchstart', closeBaloon);
    });
};
