const escKeyValues = ['Escape', 'Esc'];

const isEsc = (evt) => {
    return escKeyValues.some((code) => evt.key === code);
};

const debounce = (func, wait, immediate) => {
    let timeout;

    return function executedFunction() {
        const context = this;
        const args = arguments;

        const later = function() {
            timeout = null;
            if(!immediate) func.apply(context, args);
        }

        const callNow = immediate && !setTimeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if(callNow) func.apply(context, args);
    }
};

const APP = {
	name: 'iBrush HTML Starter'
};


// FORM


// TABS
class Tabs {
    constructor(elem) {
        this.activeClass = '_active';
        this.linkClass = 'js-tab-link';
        this.navItemClass = 'js-tab-nav-item';
        this.scrollClass = 'js-tab-scroll';

        this.$container = $(elem);

        this._addListeners();
    }

    _addListeners() {
        this._addClickListener();
        this._addWheelListener();
    }

    _addClickListener() {
        this.$container.on('click', `.${this.linkClass}`, (e) => {
            e.preventDefault();

            const $navItem = $(e.target).closest(`.${this.navItemClass}`);
            if($navItem.hasClass(this.activeClass)) return

            const id = $navItem.data('id');
            this._changeActive(id, $navItem);
        });
    }

    _addWheelListener() {
        $(`.${this.scrollClass}`).on('wheel', function(e) {
            if(this.scrollWidth <= $(this).width()) return

            e.preventDefault();
            const delta = e.originalEvent.wheelDelta;
            this.scrollLeft -= delta;
        });
    }

    _changeActive(id, $activeNavItem) {
        this.$container.find(`.${this.activeClass}`).removeClass(this.activeClass);
        this.$container.find(`[data-id="${id}"]`).addClass(this.activeClass);

        this._correctScroll($activeNavItem);
    }

    _correctScroll($activeNavItem) {
        const $scrollContainer = this.$container.find(`.${this.scrollClass}`);

        const left =  $activeNavItem.position().left - $scrollContainer.scrollLeft();
        const right = $scrollContainer.width() - $activeNavItem.width() - left;

        if(left < 0 || right < 0) {
            $scrollContainer.animate({
                scrollLeft: $activeNavItem.position().left,
            }, this.duration);
        }
    }
}

class TracerTabs {
    constructor(elem) {
        this.activeClass = '_active';
        this.linkClass = 'js-tab-link';
        this.navItemClass = 'js-tab-nav-item';
        this.scrollClass = 'js-tab-scroll';
        this.tracerClass = 'js-tab-tracer';

        this.$container = $(elem);
        this.$activeNavItem = this.$container.find(`.${this.navItemClass}.${this.activeClass}`);

        this.duration = 500;

        this._addListeners();
        this._updateTracer({immediate: true});
    }

    _addListeners() {
        this._addClickListener();
        this._addWheelListener();
        this._addResizeListener();
    }

    _addClickListener() {
        this.$container.on('click', `.${this.linkClass}`, (e) => {
            e.preventDefault();

            const $navItem = $(e.target).closest(`.${this.navItemClass}`);
            if($navItem.hasClass(this.activeClass)) return

            this._changeActive($navItem);
        });
    }

    _addWheelListener() {
        $(`.${this.scrollClass}`).on('wheel', function(e) {
            if(this.scrollWidth <= $(this).width()) return

            e.preventDefault();
            const delta = e.originalEvent.wheelDelta;
            this.scrollLeft -= delta;
        });
    }

    _addResizeListener() {
        $(window).on('resize', debounce(
            () => this._updateTracer({immediate: true}),
            300
        ));
    }

    _changeActive($navItem) {
        const id = $navItem.data('id');
        this.$container.find(`.${this.activeClass}`).removeClass(this.activeClass);
        this.$container.find(`[data-id="${id}"]`).addClass(this.activeClass);
        this.$activeNavItem = $navItem;

        this._correctScroll();
        this._updateTracer();
    }

    _correctScroll() {
        const $scrollContainer = this.$container.find(`.${this.scrollClass}`);
        const { $activeNavItem } = this;

        const left =  $activeNavItem.position().left - $scrollContainer.scrollLeft();
        const right = $scrollContainer.width() - $activeNavItem.width() - left;

        if(left < 0 || right < 0) {
            $scrollContainer.animate({
                scrollLeft: $activeNavItem.position().left,
            }, this.duration);
        }
    }

    _updateTracer({ immediate } = {}) {
        const { $activeNavItem } = this;
        const $tracer = this.$container.find(`.${this.tracerClass}`);

        const width = $activeNavItem.width();
        const left = $activeNavItem.position().left;
        const duration = immediate ? 0 : this.duration;

        $tracer.animate({
            width,
            left,
        }, duration)
    }
}


// SLIDERS
function initSlider() {
    const $sliderContainer = $('.js-slider-container')
    const $buttonPrev = $sliderContainer.find('.js-slider-prev');
    const $buttonNext = $sliderContainer.find('.js-slider-next');
    const $circle = $sliderContainer.find('.js-review-circle');
    $sliderContainer.find('.js-slider').slick(slidersConfig);


    $buttonPrev.on('mouseover', () => {
        $circle.removeClass('review__button-circle--next');
        requestAnimationFrame(() => {
            $circle.addClass('review__button-circle--prev');
        });
    });

    $buttonNext.on('mouseover', () => {
        $circle.removeClass('review__button-circle--prev');
        requestAnimationFrame(() => {
            $circle.addClass('review__button-circle--next');
        });
    });

    $buttonPrev.on('mouseout', () => {
        $circle.removeClass('review__button-circle--next');
        $circle.removeClass('review__button-circle--prev');
    });

    $buttonNext.on('mouseout', () => {
        $circle.removeClass('review__button-circle--next');
        $circle.removeClass('review__button-circle--prev');
    });
}

const slidersConfig = {
    dots: true,
    arrows: true,
    prevArrow: $('.js-slider-prev'),
    nextArrow: $('.js-slider-next'),
    infinite: false,
    adaptiveHeight: true,
    mobileFirst: true,
    responsive: [{
        breakpoint: 1199,
        settings: {
            adaptiveHeight: false,
        },
    }],
};



// SCRIPT
const setHeader = () => {
    const $header = $('.js-header');
    const $logo = $header.find('.js-logo');
    const $headerToggle = $header.find('.js-header-toggle');
    const $headerNav = $header.find('.js-header-nav');
    let isExpanded;

    const toggleMenu = (evt) => {
        evt.preventDefault();
        $header.toggleClass('header--expanded');
        isExpanded = $headerToggle.attr('aria-expanded') === 'true';
        $headerToggle.attr('aria-expanded', !isExpanded);
        isExpanded = !isExpanded;
    };

    const handleNavClick = (evt) => {
        evt.preventDefault();
        const dataNav = $(evt.target).data('nav');
        if (!dataNav) return;

        if (isExpanded) {
            toggleMenu(evt);
        }

        const yCoord = $(`[data-section=${dataNav}]`).position().top - $header.height();

        $(window).scrollTop(yCoord);

        evt.target.blur();
    }

    const setHeaderAnimation = () => {
        if (!'IntersectionObserver' in window) return;

        const $header = $('.js-header');
        const $content =$('.js-header-observer');

        const observerOptions = {
            rootMargin: '-100px',
            threshold: 0,
        };


        const observerCallback = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    $header.removeClass('header--animate');
                } else {
                    $header.addClass('header--animate');
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        observer.observe($content[0]);
    };

    setHeaderAnimation();
    $headerToggle.on('click', toggleMenu);
    $headerNav.on('click', handleNavClick);
    $logo.on('click', () => {
        $(window).scrollTop(0);
    });
};

const setIntersectionAnimation = () => {
    if (!'IntersectionObserver' in window) return;

    const $elements = $('.js-animate');

    const observerOptions = {
        rootMargin: '0px',
        threshold: 0.2,
    };


    const observerCallback = (entries, observer) => {
        entries.forEach(entry => {
            if(entry.isIntersecting) {
                $(entry.target).addClass('animate--active');

                if ($(entry.target).hasClass('footer__container')) {
                    observer.disconnect();
                }
            }
        });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    $elements.each((index, element) => {
        if ($(element).hasClass('js-animate-circle')) {
            $(element).addClass('lead__image-wrap--animate');
            $(window).on('load', () => {
                $(element).addClass('lead__image-wrap--animate-active');
            });
            return;
        }
        $(element).addClass('animate');
        if ($(element).hasClass('js-animate-onload')) {
            $(window).on('load', () => {
                $(element).addClass('animate--active animate--staged');
            });
            return;
        }
        observer.observe(element);
    });
};

const setPriceBaloon = () => {
    const $priceLink = $('.js-price-link');
    const $priceVarClose = $priceLink.find('.js-price-var-close');

    const openBaloon = (evt) => {
        evt.preventDefault();
        $(evt.target).addClass('price__link--opened');
        $(evt.target).off('touchstart', openBaloon);
        $(evt.target).on('touchstart', closeBaloon);
    }

    const closeBaloon = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        if ($(evt.target).hasClass('js-price-link')) {
            $(evt.target).removeClass('price__link--opened');
            $(evt.target).off('touchstart', closeBaloon);
            $(evt.target).on('touchstart', openBaloon);
            return;
        }

        const $link = $(evt.target).closest('.js-price-link');
        $link.removeClass('price__link--opened');
        $link.off('touchstart', closeBaloon);
        $link.on('touchstart', openBaloon);
    }

    $priceLink.each((index, link) => {
        $(link).on('touchstart', openBaloon);
    })

    $priceVarClose.each((index, button) => {
        $(button).on('touchstart', closeBaloon);
    });
};

const setWorksTabs = () => {
    const $worksToggle = $('.js-work-toggle');
    const $workCurrent = $worksToggle.find('.js-work-current');
    const $tabList = $('.js-works-tab-list');
    const $tabs = $tabList.find('.js-work-button');
    const $tables = $('.js-work-table');
    let isExpanded;

    const toggleMenu = (evt) => {
        evt.preventDefault();
        $worksToggle.toggleClass('works__tab-toggle--opened');
        isExpanded = $worksToggle.attr('aria-expanded') === 'true';
        $worksToggle.attr('aria-expanded', !isExpanded);
        isExpanded = !isExpanded;
    };

    const handleTabClick = (evt) => {
        evt.preventDefault();
        const index = $tabs.index($(evt.target));

        if ( typeof index !== 'number' ||
            $(evt.target).hasClass('works__tab-button--active')
        ) return;

        $tabs.each((i, tab) => {
            $(tab).removeClass('works__tab-button--active');
        });
        $(evt.target).addClass('works__tab-button--active');
        $workCurrent.text($(evt.target).text());

        $tables.each((i, table) => {
            $(table).removeClass('works__table--active');
        });
        $tables.eq(index).addClass('works__table--active');

        if (isExpanded) toggleMenu(evt);
    };

    $worksToggle.on('click', toggleMenu);
    $tabList.on('click', handleTabClick);
};

const setScrollTabs = () => {
    const handleNavClick = (evt) => {
        evt.preventDefault();
        const dataNav = $(evt.target).data('nav');
        if (!dataNav) return;

        const yCoord = $(`[data-section=${dataNav}]`).position().top - $('.js-header').height() - 31;

        $(window).scrollTop(yCoord);

        evt.target.blur();
    }

    $('.js-service-nav').on('click', handleNavClick);
};

const validateForm = () => {
    const PHONE_REGEX = /^(\+7|7|8)?[\s-]?\(?[49][0-9]{2}\)?[\s-]?[0-9]{2}[\s-]?([0-9][\s-]?){3}[0-9]{2}$/;
    const EMAIL_REGEX = /.+@.+\..+/;
    const $forms = $('.js-form');

    $forms.each((index, form) => {
        const $form = $(form);
        const $name = $form.find('.js-form-name');
        const $tel = $form.find('.js-form-tel');
        const $consent = $form.find('.js-form-consent');

        $tel.inputmask({
            mask: '+7 (999) 999-99-99',
            inputmode: 'tel',
        });

        $form.on('submit', (evt) => {
            evt.preventDefault();
            const isValidTel = PHONE_REGEX.test($tel.val().trim());
            const isValidName = $name.val() !== '';
            const isValidConsent = $consent.is(':checked');

            if (isValidTel && isValidName && isValidConsent) {
                $tel.removeClass('_invalid');
                $name.removeClass('_invalid');
                $consent.removeClass('_invalid');
                const formData = new FormData($form[0]);
                $.ajax({
                    type: "POST",
                    url: "/ajax/form.php",
                    data: formData,
                    processData: false,
                    contentType: false,
                }).done(() => {
                    const $popup = $(evt.currentTarget).closest('.js-form-popup');
                    if ($popup.length) {
                        closeFormPopup(evt);
                    }
                    openAjaxPopup('success');
                    $form.trigger('reset');
                }).fail(() => {
                    openAjaxPopup('error');
                });

                return;
            }

            if(!isValidName) {
                $name.addClass('_invalid');
            }

            if (!isValidTel) {
                $tel.addClass('_invalid');
            }

            if (!isValidConsent) {
                $consent.removeClass('_invalid');
                requestAnimationFrame(() => {
                    $consent.addClass('_invalid');
                });
            }
        })

        $name.on('input', () => {
            $name.removeClass('_invalid');
        });
        $tel.on('input', () => {
            $tel.removeClass('_invalid');
        });
        $consent.on('input', () => {
            $consent.removeClass('_invalid');
        });
    });
}

const setAccordion = () => {
    const $accordion = $('.js-faq-accordion');


    const onToggleClick = (evt) => {
        evt.preventDefault();

        if (!$(evt.target).hasClass('js-faq-toggle')) return;

        const $button = $(evt.target);
        const $question = $button.closest('.js-faq-question');
        const $answer = $question.parent().find('.js-faq-answer');
        const expanded = $button.attr('aria-expanded') === 'true';

        $accordion.off('click', onToggleClick);

        if (expanded) {
            $question.removeClass('faq__item-title--active');
            $answer.css('maxHeight', 0);

            $answer.one('transitionend', () => {
                $answer.css('display', 'none');
                $accordion.on('click', onToggleClick);
                });
        } else {
            $answer.css('display', 'block');
            $question.addClass('faq__item-title--active');
            $answer.css('maxHeight', $answer[0].scrollHeight + 'px');

            $answer.one('transitionend', () => {
                $accordion.on('click', onToggleClick);
            });
        }
        $button.attr('aria-expanded', !expanded);
    };

    $accordion.on('click', onToggleClick);
};

const openAjaxPopup = (responce) => {
    const $popup = responce === 'success' ? $('.js-ajax-success') : $('.js-ajax-error');

    $popup.css('display', 'flex');
    requestAnimationFrame(() => {
        $popup.addClass('ajax__popup--active');
    });
    $popup.find('.js-ajax-popup-underlay').on('click', closeAjaxPopup);
    $popup.find('.js-ajax-popup-button').on('click', closeAjaxPopup);
    $popup.find('.js-ajax-popup-close').on('click', closeAjaxPopup);
    $(document).on('keydown', onEscAjaxPopup);
};

const closeAjaxPopup = (evt) => {
    evt.preventDefault();
    const $popup = $('.ajax__popup--active');

    $popup.removeClass('ajax__popup--active');
    $popup.find('.js-ajax-popup-underlay').off('click', closeAjaxPopup);
    $popup.find('.js-ajax-popup-button').off('click', closeAjaxPopup);
    $popup.find('.js-ajax-popup-close').off('click', closeAjaxPopup);
    $(document).off('keydown', onEscAjaxPopup)

    $popup.one('transitionend', () => {
        $popup.css('display', 'none');
    });
};

const openFormPopup = (evt) => {
    evt.preventDefault();
    const $popup = $('.js-form-popup');

    $popup.css('display', 'flex');
    requestAnimationFrame(() => {
        $popup.addClass('form--active');
    });
    $popup.find('.js-form-underlay').on('click', closeFormPopup);
    $popup.find('.js-form-button').on('click', closeFormPopup);
    $popup.find('.js-form-close').on('click', closeFormPopup);
    $(document).on('keydown', onEscFormPopup)
    $(evt.target).trigger('blur');
};

const closeFormPopup = (evt) => {
    evt.preventDefault();
    const $popup = $('.form--active');
    const $form = $popup.find('.js-form');
    const $invalidInputs = $form.find('._invalid');

    $popup.removeClass('form--active');
    $popup.find('.js-form-underlay').off('click', closeFormPopup);
    $popup.find('.js-form-button').off('click', closeFormPopup);
    $popup.find('.js-form-close').off('click', closeFormPopup);
    $(document).off('keydown', onEscFormPopup)

    $popup.one('transitionend', () => {
        $popup.css('display', 'none');
        $form.trigger('reset');

        if ($invalidInputs.length) {
            $invalidInputs.each((index, input) => {
                $(input).removeClass('_invalid');
            });
        }
    });
};

const openWorkPopup = (popup) => {
    popup.css('display', 'flex');
    requestAnimationFrame(() => {
        popup.addClass('work-desc--active');
    });
    $('.js-page').addClass('no-scroll');

    popup.one('transitionend', () => {
        popup.find('.js-work-desc-close').on('click', closeWorkPopup);
        $(document).on('keydown', onEscWorkPopup);
    });
};

const closeWorkPopup = (evt) => {
    evt.preventDefault();
    const $popup = $('.work-desc--active');

    $popup.removeClass('work-desc--active');
    $('.js-page').removeClass('no-scroll');
    $popup.find('.js-work-desc-close').off('click', closeWorkPopup);
    $(document).off('keydown', onEscWorkPopup);

    $popup.one('transitionend', () => {
        $popup.css('display', 'none');
    });
};

const setPopups = () => {
    const $openFormButtons = $('.js-open-form');
    const $openWorkButtons = $('.js-work-desc-button');

    $openFormButtons.each((index, button) => {
        $(button).on('click', openFormPopup);
    });
    $openWorkButtons.each((index, button) => {
        $(button).on('click', onWorkDescTab);
    });
};

const onEscFormPopup = (evt) => {
    if (!isEsc(evt)) return;
    closeFormPopup(evt);
};

const onEscAjaxPopup = (evt) => {
    if (!isEsc(evt)) return;
    closeAjaxPopup(evt);
};

const onEscWorkPopup = (evt) => {
    if (!isEsc(evt)) return;
    closeWorkPopup(evt);
};

const onWorkDescTab = (evt) => {
    evt.preventDefault();
    const dataDesc = $(evt.target).data('desc');
        if (!dataDesc) return;
    const $popup = $(`.js-work-desc[data-desc=${dataDesc}]`);
    openWorkPopup($popup);
};

const initMap = () => {
    // Создание карты.
    const myMap = new ymaps.Map('js-map', {
        // Координаты центра карты.
        // Порядок по умолчанию: «широта, долгота».
        // Чтобы не определять координаты центра карты вручную,
        // воспользуйтесь инструментом Определение координат.
        center: [55.762287, 37.657772],
        // Уровень масштабирования. Допустимые значения:
        // от 0 (весь мир) до 19.
        zoom: 15,
        controls: [],
    });

    const myPlacemark = new ymaps.Placemark(myMap.getCenter(), {
    }, {
        // Опции.
        // Необходимо указать данный тип макета.
        iconLayout: 'default#image',
        // Своё изображение иконки метки.
        iconImageHref: './assets/img/icon-pin.svg',
        // Размеры метки.
        iconImageSize: [48, 48],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        iconImageOffset: [-24, -48]
    });

    const ZoomLayout = ymaps.templateLayoutFactory.createClass("<div class='contact__map-controls'>" +
        "<button id='zoom-in' class='js-zoom contact__map-button contact__map-button--zoomin'></button>" +
        "<button id='zoom-out' class='js-zoom contact__map-button contact__map-button--zoomout'></button>" +
        "</div>", {

        // Переопределяем методы макета, чтобы выполнять дополнительные действия
        // при построении и очистке макета.
        build: function () {
            // Вызываем родительский метод build.
            ZoomLayout.superclass.build.call(this);

            // Привязываем функции-обработчики к контексту и сохраняем ссылки
            // на них, чтобы потом отписаться от событий.
            this.zoomInCallback = ymaps.util.bind(this.zoomIn, this);
            this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this);

            // Начинаем слушать клики на кнопках макета.
            $('#zoom-in').bind('click', this.zoomInCallback);
            $('#zoom-out').bind('click', this.zoomOutCallback);
        },

        clear: function () {
            // Снимаем обработчики кликов.
            $('#zoom-in').unbind('click', this.zoomInCallback);
            $('#zoom-out').unbind('click', this.zoomOutCallback);

            // Вызываем родительский метод clear.
            ZoomLayout.superclass.clear.call(this);
        },

        zoomIn: function () {
            var map = this.getData().control.getMap();
            map.setZoom(map.getZoom() + 1, {checkZoomRange: true, duration: 200});
        },

        zoomOut: function () {
            var map = this.getData().control.getMap();
            map.setZoom(map.getZoom() - 1, {checkZoomRange: true, duration: 200});
        }
    });

    const zoomControl = new ymaps.control.ZoomControl({options: {
        layout: ZoomLayout,
        float: 'none',
        position: {
            top: '19rem',
            right: '0'
        }
    }});

    myMap.controls.add(zoomControl);
    myMap.geoObjects.add(myPlacemark);
    myMap.panes.get('ground').getElement().style.filter = 'grayscale(100%)';
}

ymaps.ready(initMap);

$(() => {
    svg4everybody();
    setHeader();
    setPriceBaloon();
    setWorksTabs();
    validateForm();
    initSlider();
    setAccordion();
    setScrollTabs();
    setPopups();
    // FORMS
    // const formFiles = FormFiles.existOnPage() ? new FormFiles() : null;

    // FormClassNames.formExistOnPage() ? new ValidateForm(formFiles) : null;

    // ScaledLabel.existOnPage() ? new ScaledLabel() : null;

    // // TABS
    // $('.js-tabs').each(function() {
    //     new Tabs(this);
    // });

    // $('.js-tracer-tabs').each(function() {
    //     new TracerTabs(this);
    // });

    // SLIDERS

    // for simple sliders

    // // adaptive
    // const $adaptiveSliderContainers = $('.js-adaptive-slider-container');
    // if($adaptiveSliderContainers.length) {
    //     new AdaptiveSliders($adaptiveSliderContainers);
    // }

});
setIntersectionAnimation();




