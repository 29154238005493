class Tabs {
    constructor(elem) {
        this.activeClass = '_active';
        this.linkClass = 'js-tab-link';
        this.navItemClass = 'js-tab-nav-item';
        this.scrollClass = 'js-tab-scroll';

        this.$container = $(elem);

        this._addListeners();
    }

    _addListeners() {
        this._addClickListener();
        this._addWheelListener();
    }

    _addClickListener() {
        this.$container.on('click', `.${this.linkClass}`, (e) => {
            e.preventDefault();

            const $navItem = $(e.target).closest(`.${this.navItemClass}`);
            if($navItem.hasClass(this.activeClass)) return

            const id = $navItem.data('id');
            this._changeActive(id, $navItem);
        });
    }

    _addWheelListener() {
        $(`.${this.scrollClass}`).on('wheel', function(e) {
            if(this.scrollWidth <= $(this).width()) return

            e.preventDefault();
            const delta = e.originalEvent.wheelDelta;
            this.scrollLeft -= delta;
        });
    }

    _changeActive(id, $activeNavItem) {
        this.$container.find(`.${this.activeClass}`).removeClass(this.activeClass);
        this.$container.find(`[data-id="${id}"]`).addClass(this.activeClass);

        this._correctScroll($activeNavItem);
    }

    _correctScroll($activeNavItem) {
        const $scrollContainer = this.$container.find(`.${this.scrollClass}`);

        const left =  $activeNavItem.position().left - $scrollContainer.scrollLeft();
        const right = $scrollContainer.width() - $activeNavItem.width() - left;

        if(left < 0 || right < 0) {
            $scrollContainer.animate({
                scrollLeft: $activeNavItem.position().left,
            }, this.duration);
        }
    }
}
