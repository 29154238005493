class TracerTabs {
    constructor(elem) {
        this.activeClass = '_active';
        this.linkClass = 'js-tab-link';
        this.navItemClass = 'js-tab-nav-item';
        this.scrollClass = 'js-tab-scroll';
        this.tracerClass = 'js-tab-tracer';

        this.$container = $(elem);
        this.$activeNavItem = this.$container.find(`.${this.navItemClass}.${this.activeClass}`);

        this.duration = 500;

        this._addListeners();
        this._updateTracer({immediate: true});
    }

    _addListeners() {
        this._addClickListener();
        this._addWheelListener();
        this._addResizeListener();
    }

    _addClickListener() {
        this.$container.on('click', `.${this.linkClass}`, (e) => {
            e.preventDefault();

            const $navItem = $(e.target).closest(`.${this.navItemClass}`);
            if($navItem.hasClass(this.activeClass)) return

            this._changeActive($navItem);
        });
    }

    _addWheelListener() {
        $(`.${this.scrollClass}`).on('wheel', function(e) {
            if(this.scrollWidth <= $(this).width()) return

            e.preventDefault();
            const delta = e.originalEvent.wheelDelta;
            this.scrollLeft -= delta;
        });
    }

    _addResizeListener() {
        $(window).on('resize', debounce(
            () => this._updateTracer({immediate: true}),
            300
        ));
    }

    _changeActive($navItem) {
        const id = $navItem.data('id');
        this.$container.find(`.${this.activeClass}`).removeClass(this.activeClass);
        this.$container.find(`[data-id="${id}"]`).addClass(this.activeClass);
        this.$activeNavItem = $navItem;

        this._correctScroll();
        this._updateTracer();
    }

    _correctScroll() {
        const $scrollContainer = this.$container.find(`.${this.scrollClass}`);
        const { $activeNavItem } = this;

        const left =  $activeNavItem.position().left - $scrollContainer.scrollLeft();
        const right = $scrollContainer.width() - $activeNavItem.width() - left;

        if(left < 0 || right < 0) {
            $scrollContainer.animate({
                scrollLeft: $activeNavItem.position().left,
            }, this.duration);
        }
    }

    _updateTracer({ immediate } = {}) {
        const { $activeNavItem } = this;
        const $tracer = this.$container.find(`.${this.tracerClass}`);

        const width = $activeNavItem.width();
        const left = $activeNavItem.position().left;
        const duration = immediate ? 0 : this.duration;

        $tracer.animate({
            width,
            left,
        }, duration)
    }
}
