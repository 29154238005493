const setIntersectionAnimation = () => {
    if (!'IntersectionObserver' in window) return;

    const $elements = $('.js-animate');

    const observerOptions = {
        rootMargin: '0px',
        threshold: 0.2,
    };


    const observerCallback = (entries, observer) => {
        entries.forEach(entry => {
            if(entry.isIntersecting) {
                $(entry.target).addClass('animate--active');

                if ($(entry.target).hasClass('footer__container')) {
                    observer.disconnect();
                }
            }
        });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    $elements.each((index, element) => {
        if ($(element).hasClass('js-animate-circle')) {
            $(element).addClass('lead__image-wrap--animate');
            $(window).on('load', () => {
                $(element).addClass('lead__image-wrap--animate-active');
            });
            return;
        }
        $(element).addClass('animate');
        if ($(element).hasClass('js-animate-onload')) {
            $(window).on('load', () => {
                $(element).addClass('animate--active animate--staged');
            });
            return;
        }
        observer.observe(element);
    });
};
